<template>
    <ion-page>
        <app-header back="home" title="거래처 일별 출하현황" />
        <ion-content :fullscreen="true">
            <template v-if="isConnected">
                <div id="container">
                    <ion-grid>
                        <ion-row class="ion-justify-content-left">
                            <ion-col size-md="1" size-xs="1.5">
                                <span> 기간</span>
                            </ion-col>
                            <ion-col size-md="2" size-xs="5">
                                <ion-input class="calendar" v-model="frDate" id="frdate" readonly
                                    @click="popChange"><ion-icon :icon="calendar"></ion-icon></ion-input>
                                <ion-popover trigger="frdate" @didDismiss="onWillDismissPopover">
                                    <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM-DD"
                                        min="2021-01-01" v-model="frDate" presentation="date"
                                        :show-default-buttons="true"></ion-datetime>
                                </ion-popover>
                            </ion-col>
                            <ion-col size-md="1" size-xs="0.5">
                                <span>~</span>
                            </ion-col>
                            <ion-col size-md="2" size-xs="5">
                                <ion-input class="calendar" v-model="toDate" id="todate" readonly
                                    @click="popChange"><ion-icon :icon="calendar"></ion-icon></ion-input>
                                <ion-popover trigger="todate" @didDismiss="onWillDismissPopover">
                                    <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM-DD"
                                        min="2021-01-01" v-model="toDate" presentation="date"
                                        :show-default-buttons="true"></ion-datetime>
                                </ion-popover>
                            </ion-col>
                            <ion-col size-md="2" size-xs="8">
                                <ion-input id="open-modal1" class="dplace" placeholder="착지 검색" readonly
                                    style="text-align: center; align-items: center; margin: auto;"
                                    @ionInput="cgname($event)" @click="modalChange"></ion-input>
                                <ion-modal ref="modal1" trigger="open-modal1" @willDismiss="onWillDismiss">
                                    <ion-header>
                                        <ion-toolbar>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <ion-buttons slot="start">
                                                <ion-button color="medium" @click="clear()">Clear</ion-button>
                                            </ion-buttons>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <ion-buttons slot="end">
                                                <ion-button color="medium" @click="cancel()">Cancel</ion-button>
                                            </ion-buttons>
                                        </ion-toolbar>
                                        <ion-item>
                                            <ion-input style="margin-left: 10px" class="ion-text-left" type="text"
                                                placeholder="착지를 입력하세요." @input="searchGroup($event)"></ion-input>
                                        </ion-item>
                                    </ion-header>
                                    <ion-content class="my-modal-class4">
                                        <ion-list style="text-align: left">
                                            <ion-item class="group-list"  v-for="detail in daydplacelist" :key="detail">
                                                <ion-label style="text-align: left" ref="input" class="dplacename"
                                                    @click="sendDplNm(detail.dplaceName)"> {{
                                                        detail.dplaceName
                                                    }}</ion-label>
                                            </ion-item>
                                        </ion-list>
                                    </ion-content>
                                </ion-modal>
                            </ion-col>
                            <ion-col size-md="1" size-xs="4">
                                <ion-select class="ion-no-padding" interface="popover" @ionChange="selectCatecd($event)"
                                    :value="cate.code" style="padding-left: 10px;">
                                    <ion-select-option value="1">차량별</ion-select-option>
                                    <ion-select-option value="2">일자별</ion-select-option>
                                </ion-select>
                            </ion-col>
                            <ion-col size-md="1" size-xs="6">
                                <ion-button expand="block" class="excel" @click="exportToExcel"
                                    color="success">엑셀변환</ion-button>
                            </ion-col>
                            <ion-col size-md="1" size-xs="6">
                                <ion-button expand="block" @click="listSearch" color="medium">조회</ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <p class="ton" style="margin-top: 3px">(단위,톤)</p>
                    <vue-good-table v-if="cate.code === '1'" :columns="columns" :rows="chulhalist" :line-numbers="false"
                        :row-style-class="rowStyleFunction" max-height="70vh">
                    </vue-good-table>
                    <vue-good-table v-else :columns="columns2" :rows="chulhalist" :line-numbers="false"
                        :row-style-class="rowStyleFunction" max-height="70vh">
                    </vue-good-table>
                </div>
            </template>
            <template v-else>
                <network-error v-on:@onReConnect="onReConnect" />
            </template>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
    IonDatetime,
    IonContent,
    IonInput,
    IonButton,
    modalController,
    IonPopover,
    IonIcon,
    onIonViewWillLeave,
    popoverController,
    onIonViewWillEnter,
    loadingController,
    IonModal,
    IonToolbar,
    IonHeader,
    IonSelectOption,
    IonSelect,
    IonButtons,
    IonItem,
    IonLabel,
    IonList
} from '@ionic/vue';

import { ref, reactive, defineComponent, computed, watch } from 'vue';
import { useStore } from 'vuex';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';
import { setDateFormat } from '@/utils/dateFormatter';
import { chulhas } from '@/api/services/chulha';
import * as XLSX from 'xlsx';
import { calendar } from 'ionicons/icons';
import { users } from '@/api/services/user';


export default defineComponent({
    components: {
        IonPage,
        IonIcon,
        IonDatetime,
        IonButton,
        IonCol,
        IonRow,
        IonGrid,
        IonPopover,
        IonContent,
        IonInput,
        VueGoodTable,
        IonModal,
        IonToolbar,
        IonHeader,
        IonSelectOption,
        IonSelect,
        IonButtons,
        IonItem,
        IonLabel,
        IonList
    },
    setup() {
        const store = useStore();
        const auth = computed(() => store.getters['auth/getData']);
        const { isConnected, chulhalist, fetchDatas, fetchDplaceDatas, daydplacelist } = chulhas();
        const { saveLog } = users();

        onIonViewWillEnter(async () => {
            saveLog(auth.value.id, '거래처일별출하현황');
            fetchDplaceDatas();
        });

        const now = (new Date());
        const frDate = ref(setDateFormat(new Date(now.getFullYear(), now.getMonth(), 1), 'YYYY-MM-DD')); //ref('2023-01-01');
        const toDate = ref(setDateFormat(new Date(), 'YYYY-MM-DD'));
        const salenm = reactive({
            title: '',
        });
        const form = reactive({
            modalState: false,
            popoverState: false
        });
        async function onWillDismiss() {
            form.modalState = false;
        }
        async function onWillDismissPopover() {
            form.popoverState = false;
        }
        async function modalChange() {
            const loading = await loadingController.create({
                message: '로딩중...',
                duration: 300
            });

            loading.present();
            form.modalState = true;
        }
        async function popChange() {
            form.popoverState = true;
        }

        const dplacenm = reactive({
            title: '',
        });

        const cate = reactive({
            code: '1',
        });

        function selectCatecd(e) {
            cate.code = e.target.value;
        }

        function cgname(e) {
            if (e == null) {
                document.querySelector('.dplace').value = null;

            } else {
                document.querySelector('.dplace').value = e;
            }
        }

        async function sendDplNm(dplnm) {
            this.$refs.modal1.$el.dismiss(null, 'confirm');
            cgname(dplnm.substring(8));
            dplacenm.title = dplnm.substring(0, 6);
            console.log(dplacenm.title);

        }

        async function listSearch() {

            await fetchDatas(frDate.value.substring(0, 10).replaceAll('-', ''), toDate.value.substring(0, 10).replaceAll('-', ''), dplacenm.title, cate.code);

        }
        function customd01(row) {
            if (row.saleQunt) return row.saleQunt.toLocaleString('ko-KR', { minimumFractionDigits: 2 });

        }
        function customd02(row) {
            if (row.saleTwgh) return row.saleTwgh.toLocaleString('ko-KR', { minimumFractionDigits: 2 });

        }
        function customd03(row) {
            if (row.saleGwgh) return row.saleGwgh.toLocaleString('ko-KR', { minimumFractionDigits: 2 });

        }

        function customdc01(row) {
            if (row.saleQunt) return 'currency';
        }
        function customdc02(row) {
            if (row.saleTwgh) return 'currency';
        }
        function customdc03(row) {
            if (row.saleGwgh) return 'currency';
        }

        const columns = reactive([
            {
                label: '청구처',
                field: 'saleName',
                width: '120px',
                sortable: false,
                //tdClass: customd01 
            },
            {
                label: '출하일자',
                field: 'saDateNm',
                width: '100px',
                sortable: false,
                //tdClass: customd02
            },
            {
                label: '착지',
                field: 'dplaceName',
                width: '120px',
                sortable: false,
                //tdClass: customd03
            },
            {
                label: '품목',
                field: 'itnm',
                width: '100px',
                sortable: false,
                //tdClass: customd04
            },
            {
                label: '출하시간',
                field: 'saTime',
                width: '100px',
                sortable: false,
                //tdClass: customd04
            },
            {
                label: '차량번호',
                field: 'saCarno',
                width: '100px',
                sortable: false,
                //tdClass: customd04
            },
            {
                label: '기사',
                field: 'saKisa',
                width: '80px',
                sortable: false,
                //tdClass: customd04
            },
            {
                label: '총중량',
                field: customd02,
                width: '80px',
                sortable: false,
                tdClass: customdc02
            },
            {
                label: '공차중량',
                field: customd03,
                width: '100px',
                sortable: false,
                tdClass: customdc03
            },
            {
                label: '출하량',
                field: customd01,
                width: '80px',
                sortable: false,
                tdClass: customdc01
            }

        ]);

        const columns2 = reactive([
            {
                label: '청구처',
                field: 'saleName',
                width: '120px',
                sortable: false,
                //tdClass: customd01 
            },
            {
                label: '출하일자',
                field: 'saDateNm',
                width: '100px',
                sortable: false,
                //tdClass: customd02
            },
            {
                label: '착지',
                field: 'dplaceName',
                width: '120px',
                sortable: false,
                //tdClass: customd03
            },
            {
                label: '품목',
                field: 'itnm',
                width: '100px',
                sortable: false,
                //tdClass: customd04
            },
            {
                label: '출하량',
                field: customd01,
                width: '80px',
                sortable: false,
                tdClass: customdc01
            }

        ]);

        watch(() => cate.code, async (newValue, oldValue) => {
            if (oldValue != '' && newValue != oldValue) {
                chulhalist.value = [];
            }
        })

        async function cancel() {
            this.$refs.modal1.$el.dismiss(null, 'cancel');
        }

        async function clear() {
            this.$refs.modal1.$el.dismiss(null, 'cancel');
            document.querySelector('.dplace').value = null;
            dplacenm.title = "";
        }

        async function searchGroup(event) {
            //console.log(this.salelist[0].saleName);

            const filter = event.target.value;

            if (filter.length < 2) {
                return;
            }

            const dplaceNames = Array.from(document.querySelectorAll('.dplacename'));
            const groupLists = Array.from(document.querySelectorAll('.group-list'));

            this.daydplacelist.forEach((item, index) => {
                const shouldShow = item.dplaceName.includes(filter);
                dplaceNames[index].style.display = shouldShow ? "flex" : "none";
                groupLists[index].style.display = shouldShow ? "flex" : "none";
            });


        }



        const exportToExcel = () => {
            if(cate.code === "1"){
                const data = Object.values(chulhalist.value)
                    .map(row => [row.saleName, row.saDateNm, row.dplaceName, row.itnm, row.saTime, row.saCarno, row.saKisa, row.saleTwgh, row.saleGwgh, row.saleQunt]);
                    data.unshift(columns.map(col => col.label));
                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.aoa_to_sheet(data);
                    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                    XLSX.writeFile(wb, "거래처일별출하현황.xlsx");
            }
            else if (cate.code === "2"){
                const data = Object.values(chulhalist.value)
                    .map(row => [row.saleName, row.saDateNm, row.dplaceName, row.itnm, row.saleQunt]);
                    data.unshift(columns2.map(col => col.label));
                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.aoa_to_sheet(data);
                    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                    XLSX.writeFile(wb, "거래처일별출하현황.xlsx");
            }
        }
        onIonViewWillLeave(async () => {
            if (form.modalState == true) {
                await modalController.dismiss()
            } else if (form.popoverState == true) {
                await popoverController.dismiss()
            }
        });

        function rowStyleFunction(row) {
            if (row.saDateNm) {
                if (row.saDateNm.includes('소계')) {
                    return 'row-subtotal-color';
                } else if (row.saDateNm.includes('합계')) {
                    return 'row-total-color';
                } else if (row.saDateNm.includes('총계')) {
                    return 'row-all-total-color'
                }
            }
        }

        return {
            isConnected,
            frDate,
            toDate,
            listSearch,
            chulhalist,
            //items,
            columns,
            salenm,
            cgname,
            exportToExcel,
            calendar,
            auth,
            onWillDismiss,
            onWillDismissPopover,
            modalChange,
            popChange,
            rowStyleFunction,
            searchGroup,
            cancel,
            clear,
            cate,
            selectCatecd,
            columns2,
            daydplacelist,
            dplacenm,
            sendDplNm
        };
    }

});

</script>

<style scoped>
#container {
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
}

.ton {
    text-align: right;
    font-size: 13px;
}

ion-item {
    text-align: center;
    height: 40px;

}

ion-grid {
    padding: 0;

}

ion-col {
    padding: 0;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;

}

ion-modal#example-modal {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-col .calendar {
    padding: 0;
}

ion-input {
    word-break: break-word;
}
.group-list {
    margin: auto;
    display: flex;
}

.group-list ion-label {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 32px;
    list-style-type: none;
}
</style>